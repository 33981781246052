import type { ZodType } from 'zod'

import { countryConfig } from '../countryConfig'

/**
 * Get a value from local storage, parsing it with the provided schema and
 * returning a default value if the value is not found or invalid.
 */
export const getLocalStorageValue = <T>(key: string, schema: ZodType<T>, defaultValue: T): T => {
  if (typeof window === 'undefined') {
    return defaultValue
  }
  const storedData = window.localStorage.getItem(getFullKey(key))
  if (!storedData) {
    return defaultValue
  }
  try {
    return schema.parse(JSON.parse(storedData))
  } catch {
    return defaultValue
  }
}

export const setLocalStorageValue = (key: string, value: unknown) => {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(getFullKey(key), JSON.stringify(value))
}

const getFullKey = (key: string) => {
  return `${countryConfig.localStoragePrefix}${key}`
}
