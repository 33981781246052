'use client'

import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { ContentText, Section, spacing } from '@fortum/elemental-ui'

import unplugged from '@/images/unplugged.svg'
import { StyledHeading } from '@/shared/components/StyledHeading'
import { StyledLink } from '@/shared/components/StyledLink'
import { logError } from '@/shared/utils/error'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'

export default function NotFound() {
  const t = useTranslations('notFoundPage')
  const initialized = useRef(false)

  useEffect(() => {
    logError('Page not found')
    if (!initialized.current) {
      // Ran twice on first render, so we need to check if it's already initialized
      initialized.current = true
      sendGTMEvent({
        event: 'error_event',
        error_type: 'error_404',
      })
    }
  }, [])

  return (
    <Section textAlign="center">
      <StyledHeading level={1} mb={spacing.xs}>
        {t('title')}
      </StyledHeading>
      <StyledImage src={unplugged} alt={t('imageAltText')} />
      <ContentText paragraph size="l">
        {t('content')}
      </ContentText>
      <StyledLink href="/" size="l">
        {t('homePageLink')}
      </StyledLink>
    </Section>
  )
}

const StyledImage = styled(Image)`
  width: 64rem;
  margin: 0 auto ${spacing.xs};
`
